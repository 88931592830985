import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/": [~6],
		"/about": [7],
		"/actions": [8],
		"/artists/[slug]": [~9,[2]],
		"/artists/[slug]/tracks/new": [~12,[2]],
		"/artists/[slug]/tracks/[id]/asks/[[index]]": [~10,[2,3]],
		"/artists/[slug]/tracks/[id]/edit": [~11,[2,3]],
		"/check-your-email": [13],
		"/cookie-policy": [14],
		"/faqs": [15],
		"/feed": [~16],
		"/latest-tracks": [~17],
		"/license-agreement": [18],
		"/music/[[slug]]/schedules": [20,[4]],
		"/music/[[slug]]/schedules/[year]": [21,[4]],
		"/music/[[slug]]/schedules/[year]/[month]": [22,[4]],
		"/music/[[slug]]/schedules/[year]/[month]/[day]": [~23,[4]],
		"/music/[[slug]]": [~19,[4]],
		"/privacy-policy": [24],
		"/profile": [~25,[5]],
		"/profile/favourites": [~26,[5]],
		"/profile/pledge/[[date]]": [~27,[5]],
		"/profile/shares": [~28,[5]],
		"/schedules/[...rest]": [31],
		"/sign-in-email": [33],
		"/sign-in": [32],
		"/style-guide": [34],
		"/s": [~29],
		"/s/[id]": [~30],
		"/users/new": [~35]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';