import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
  tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
  integrations: [new Sentry.Replay()],
  replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_RATE,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.VITE_ENV
});

const myErrorHandler = ({ error, event }) => {
  console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
